import { getTranslation } from "./Helper"
import { useState } from 'react'
import { Disclosure, } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { CalendarDaysIcon, FlagIcon } from "@heroicons/react/24/outline";
import logo from '../logo.svg'


const content = {
    path: {
        'fr': '/en',
        'en': '/'
    },
    currentpath: {
        'fr': '/',
        'en': '/en'
    },
    pathMentions: {
        'fr': '/en/legal-notices',
        'en': '/mentions-legales'
    },
    abbreviation: {
        'fr': 'EN',
        'en': 'FR'
    },
    open: {
        'fr': 'Ouvrir le menu principal',
        'en': 'Open main menu'
    },
    rdv: {
        'fr': 'Prendre RDV',
        'en': 'Book an Appointment'
    },
    equipe: {
        'fr': 'L\'équipe',
        'en': 'Our Team'
    },
    services: {
        'fr': 'Les services',
        'en': 'Our Services'
    },
    contact: {
        'fr': 'Contact',
        'en': 'Contact Us'
    },
    information: {
        'fr': 'Chaîne Youtube',
        'en': 'Youtube Channel'
    },
}

export default function NavBar({ setOpen, lang, context }) {

    const [currentLink, setCurrentLink] = useState('#');

    const handleLinkClick = (link) => {
        setCurrentLink(link);
    };

    const linkClassName = (link) => {
        return currentLink === link
            ? "inline-flex items-center border-b-2 border-sky-500 px-1 pt-1 text-sm font-medium text-gray-900"
            : "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700";
    };

    const mobileLinkClassName = (link) => {
        return currentLink === link
            ? "block border-l-4 border-sky-500 bg-sky-50 py-2 pl-3 pr-4 text-base font-medium text-sky-700 sm:pl-5 sm:pr-6"
            : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6";
    };

    return (
        < Disclosure as="nav" className="fixed top-0 w-full z-50 bg-white shadow" >
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 justify-between">
                            <div className="flex">
                                <div className="-ml-2 mr-2 flex items-center md:hidden">
                                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500">
                                        <span className="absolute -inset-0.5" />
                                        <span className="sr-only">{getTranslation(content.open, lang)}</span>
                                        {open ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                                <div className="hidden md:ml-6 md:flex md:space-x-8">
                                    <a
                                        href={getTranslation(content.currentpath, lang)}
                                        className={linkClassName('#')}
                                        onClick={() => handleLinkClick('#')}
                                    >
                                        <img className="w-10 h-10" src={logo} />
                                    </a>
                                    <a
                                        href={getTranslation(content.currentpath, lang) + '#equipe'}
                                        className={linkClassName('#equipe')}
                                        onClick={() => handleLinkClick('#equipe')}
                                    >
                                        {getTranslation(content.equipe, lang)}
                                    </a>
                                    <a
                                        href={getTranslation(content.currentpath, lang) + '#services'}
                                        className={linkClassName('#services')}
                                        onClick={() => handleLinkClick('#services')}
                                    >
                                        {getTranslation(content.services, lang)}
                                    </a>
                                    <a
                                        href={getTranslation(content.currentpath, lang) + '#contact'}
                                        className={linkClassName('#contact')}
                                        onClick={() => handleLinkClick('#contact')}
                                    >
                                        {getTranslation(content.contact, lang)}
                                    </a>
                                    <a
                                        href={getTranslation(content.currentpath, lang) + '#infos'}
                                        className={linkClassName('#infos')}
                                        onClick={() => handleLinkClick('#infos')}
                                    >
                                        {getTranslation(content.information, lang)}
                                    </a>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <button
                                        type="button"
                                        className="relative inline-flex items-center gap-x-1.5 rounded-md bg-orange-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
                                        onClick={() => setOpen()}
                                    >
                                        <CalendarDaysIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                        {getTranslation(content.rdv, lang)}
                                    </button>
                                </div>
                                <div className="flex-shrink-0 ml-3">
                                    <a href={context === 'mentions' ? getTranslation(content.pathMentions, lang) : getTranslation(content.path, lang)}>
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md bg-white px-3 py-2.5 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        >
                                            <FlagIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
                                            <span className="text-xs pl-1">{getTranslation(content.abbreviation, lang)}</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="md:hidden">
                        <div className="space-y-1 pb-3 pt-2">
                            <Disclosure.Button
                                as="a"
                                href={getTranslation(content.currentpath, lang) + '#equipe'}
                                className={mobileLinkClassName('#equipe')}
                                onClick={() => handleLinkClick('#equipe')}
                            >
                                {getTranslation(content.equipe, lang)}
                            </Disclosure.Button>
                            <Disclosure.Button
                                as="a"
                                href={getTranslation(content.currentpath, lang) + '#services'}
                                className={mobileLinkClassName('#services')}
                                onClick={() => handleLinkClick('#services')}
                            >
                                {getTranslation(content.services, lang)}
                            </Disclosure.Button>
                            <Disclosure.Button
                                as="a"
                                href={getTranslation(content.currentpath, lang) + '#contact'}
                                className={mobileLinkClassName('#contact')}
                                onClick={() => handleLinkClick('#contact')}
                            >
                                {getTranslation(content.contact, lang)}
                            </Disclosure.Button>
                            <Disclosure.Button
                                as="a"
                                href={getTranslation(content.currentpath, lang) + '#infos'}
                                className={mobileLinkClassName('#infos')}
                                onClick={() => handleLinkClick('#infos')}
                            >
                                {getTranslation(content.information, lang)}
                            </Disclosure.Button>
                        </div>
                    </Disclosure.Panel>
                </>
            )
            }
        </Disclosure >
    )
}
