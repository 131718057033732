import React from 'react';
import {
    Body,
    Button,
    Container,
    Head,
    Hr,
    Html,
    Preview,
    Row,
    Section,
    Text
} from "@react-email/components";
import parse from 'html-react-parser';

// Safely parse potentially non-string values
const safeParse = (content) => {
    return typeof content === 'string' ? parse(content) : '';
};

// EmailTemplate component
const MailContact = ({ data, lang }) => {
    const mailto = 'mailto:' + data.email;
    const styles = {
        main: {
            backgroundColor: "#ffffff",
            fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
        },
        container: {
            margin: "0 auto",
            padding: "20px 0 48px",
            width: "580px",
            maxWidth: "100%",
        },
        heading: {
            fontSize: "32px",
            lineHeight: "1.3",
            fontWeight: "700",
            color: "#484848",
        },
        client: {
            fontSize: "18px",
            lineHeight: "1.4",
            color: "#484848",
            borderColor: "#484848",
            padding: "0 24px 0 24px",
            borderLeft: "4px solid #484848",
        },
        paragraph: {
            fontSize: "18px",
            lineHeight: "0.2",
            color: "#484848",
            paddingTop: "6px",
        },
        lang: {
            fontSize: "14px",
            lineHeight: "1.4",
            color: "#484848",
            paddingTop: "18px",
        },
        bold: {
            fontWeight: "bold",
        },
        review: {
            fontSize: "16px",
            fontStyle: "italic",
            lineHeight: "1.4",
            color: "#484848",
            padding: "24px",
            backgroundColor: "#f2f3f3",
            borderRadius: "4px",
        },
        button: {
            backgroundColor: "#0284c7",
            borderRadius: "3px",
            color: "#fff",
            fontSize: "18px",
            paddingTop: "19px",
            paddingBottom: "19px",
            textDecoration: "none",
            textAlign: "center",
            display: "block",
            width: "100%",
        },
        hr: {
            borderColor: "#cccccc",
            margin: "20px 0",
        },
        footer: {
            color: "#9ca299",
            fontSize: "14px",
            marginBottom: "10px",
        },
    };

    // Applying the styles.review directly and spreading styles.paragraph into styles.review
    styles.review = { ...styles.paragraph, ...styles.review };

    return (
        <Html>
            <Head />
            <Preview>Nouveau message depuis le site Internet</Preview>

            <Body style={styles.main}>
                <Container style={styles.container}>
                    <Section style={{ paddingBottom: "20px" }}>
                        <Row>
                            <Text style={styles.heading}>Vous avez reçu un message depuis le site Internet</Text>
                            <Text style={styles.client}>
                                <span style={styles.bold}>Prénom : </span>{data.prenom}
                                <br />
                                <span style={styles.bold}>Nom : </span>{data.nom}
                                <br />
                                <span style={styles.bold}>Email : </span>{data.email}
                                <br />
                                <span style={styles.bold}>Téléphone : </span>{data.telephone}
                            </Text>
                            <Text style={styles.paragraph}>
                                &nbsp;
                            </Text>
                            <Text style={styles.paragraph}>
                                Contenu du message :
                            </Text>
                            <Text style={styles.review}>{safeParse(data.message)}</Text>
                            <Text style={styles.lang}>
                                (Le client a envoyé son email depuis la version <span style={styles.bold}>{lang === 'fr' ? 'française' : 'anglaise'}</span> du site.)
                            </Text>
                            <Button style={styles.button} href={mailto}>
                                Répondre au client
                            </Button>
                        </Row>
                    </Section>

                    <Hr style={styles.hr} />

                    <Section>
                        <Row>
                            <Text style={styles.footer}>
                                © Maître Solal Cloris
                            </Text>
                        </Row>
                    </Section>
                </Container>
            </Body>
        </Html>
    );
};

export default MailContact;