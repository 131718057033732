import React from 'react';
import {
    Body,
    Container,
    Head,
    Hr,
    Html,
    Preview,
    Row,
    Section,
    Text
} from "@react-email/components";
import parse from 'html-react-parser';
import { getTranslation } from '../components/Helper';

const contenu = {
    title: {
        'fr': 'Maître Cloris a bien reçu votre email',
        'en': 'Maître Cloris Has Received Your Email'
    },
    desc: {
        'fr': 'M<sup>e</sup> Cloris a bien reçu votre email et vous en remercie. Il reviendra très bientôt vers vous.',
        'en': 'Maître Cloris has received your email and thanks you for reaching out. He will get back to you shortly.'
    },
    nom: {
        'fr': 'M<sup>e</sup> Cloris a bien reçu votre email.',
        'en': 'Maître Cloris Has Received Your Email'
    },
    heading: {
        'fr': 'Merci pour votre message.',
        'en': 'Thank you for your Email'
    },
    message: {
        'fr': 'Votre message :',
        'en': 'Your email:'
    }
}
// Safely parse potentially non-string values
const safeParse = (content) => {
    return typeof content === 'string' ? parse(content) : '';
};

// EmailTemplate component
const MailAccuse = ({ data, lang }) => {
    const styles = {
        main: {
            backgroundColor: "#ffffff",
            fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
        },
        container: {
            margin: "0 auto",
            padding: "20px 0 48px",
            width: "580px",
            maxWidth: "100%",
        },
        heading: {
            fontSize: "32px",
            lineHeight: "1.3",
            fontWeight: "700",
            color: "#484848",
        },
        paragraph: {
            fontSize: "18px",
            lineHeight: "1.4",
            color: "#484848",
        },
        review: {
            fontSize: "18px",
            lineHeight: "1.4",
            color: "#484848",
            padding: "24px",
            backgroundColor: "#f2f3f3",
            borderRadius: "4px",
        },
        hr: {
            borderColor: "#cccccc",
            margin: "20px 0",
        },
        footer: {
            color: "#9ca299",
            fontSize: "14px",
            marginBottom: "10px",
        },
    };

    // Applying the styles.review directly and spreading styles.paragraph into styles.review
    styles.review = { ...styles.paragraph, ...styles.review };

    return (
        <Html>
            <Head />
            <Preview>{getTranslation(contenu.title, lang)}</Preview>

            <Body style={styles.main}>
                <Container style={styles.container}>
                    <Section style={{ paddingBottom: "20px" }}>
                        <Row>
                            <Text style={styles.heading}>{getTranslation(contenu.heading, lang)}</Text>
                            <Text style={styles.paragraph}>
                                {getTranslation(contenu.desc, lang)}
                            </Text>
                            <Text style={styles.paragraph}>
                                {getTranslation(contenu.message, lang)}
                            </Text>
                            <Text style={styles.review}>{safeParse(data.message)}</Text>
                        </Row>
                    </Section>

                    <Hr style={styles.hr} />

                    <Section>
                        <Row>
                            <Text style={styles.footer}>
                                © Maître Solal Cloris
                            </Text>
                        </Row>
                    </Section>
                </Container>
            </Body>
        </Html>
    );
};

export default MailAccuse;