import React from 'react';

const Mentions = ({ lang }) => {

    if (lang === "en") {
        return (
            <div className="container mt-20 md:mt-24 mx-auto px-12 md:px-24 py-8">
                <h1 className="text-3xl font-bold mb-4">Legal Notices</h1>

                <section>
                    <p className="mb-4">
                        Please carefully read the terms of use of this site before browsing it. By logging onto this site, you unreservedly accept these terms.
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">1. Legal Information:</h2>
                    <ul className="list-disc pl-5 mb-4">
                        <li>Status of the owner: Lawyer</li>
                        <li>The Owner is: Solal CLORIS</li>
                        <li>Owner's postal address: 59, avenue du Général de Gaulle – 94160 Saint-Mandé</li>
                        <li>The Publishing Manager is: Solal CLORIS - Contact the publishing manager: solal@cloris-avocat.fr</li>
                        <li>The website host is: Infomaniak Network SA - Rue Eugène-Marziano 25, 1227 Geneva, Switzerland</li>
                    </ul>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">2. Presentation and principle:</h2>
                    <p className="mb-4">
                        Hereinafter referred to as: User, any internet user connecting and using the aforementioned site: www.cloris-avocat.fr. The site includes a set of services, as is, made available to users. It is specified that these users must remain courteous and show good faith.
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">3. Accessibility:</h2>
                    <p className="mb-4">
                        The site www.cloris-avocat.fr is in principle accessible to users 24/24h, 7/7d, except interruption, scheduled or not, for the needs of its maintenance or in case of force majeure.
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">4. Intellectual property:</h2>
                    <p className="mb-4">
                        Master Solal CLORIS is the exclusive owner of all intellectual property rights or holds the rights of use on all the elements accessible on the site, both on the structure and on the texts, images, graphics, logo, icons, sounds, software...
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">5. Hyperlinks and cookies:</h2>
                    <p className="mb-4">
                        The site www.cloris-avocat.fr may contain a number of hyperlinks to other sites, set up with the authorization of Master Solal CLORIS. Our site does not use cookies and therefore does not collect any data via cookies.
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">6. Protection of property and individuals - management of personal data:</h2>
                    <p className="mb-4">
                        In France, personal data is notably protected by law no. 78-87 of January 6, 1978, law no. 2004-801 of August 6, 2004, article L. 226-13 of the Penal Code, and the European Directive of October 24, 1995.
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">7. Management of personal data:</h2>
                    <p className="mb-4">
                        The site www.cloris-avocat.fr collects personal information relating to the user only for the need of certain services offered by the site. The user provides this information with full knowledge of the facts, especially when he proceeds to enter them himself.
                    </p>
                </section>
            </div>
        );
    } else {
        return (
            <div className="container mt-20 md:mt-24 mx-auto px-12 md:px-24 py-8">
                <h1 className="text-3xl font-bold mb-4">Mentions Légales</h1>

                <section>
                    <p className="mb-4">
                        Merci de lire attentivement les présentes modalités d'utilisation du présent site avant de le parcourir. En vous connectant sur ce site, vous acceptez sans réserve les présentes modalités.
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">1. Informations légales :</h2>
                    <ul className="list-disc pl-5 mb-4">
                        <li>Statut du propriétaire : Avocat</li>
                        <li>Le Propriétaire est : Solal CLORIS</li>
                        <li>Adresse postale du propriétaire : 59, avenue du Général de Gaulle – 94160 Saint-Mandé</li>
                        <li>Le Responsable de la publication est : Solal CLORIS - Contacter le responsable de la publication : solal@cloris-avocat.fr</li>
                        <li>L’hébergeur du site est : Infomaniak Network SA - Rue Eugène-Marziano 25, 1227 Genève, Suisse</li>
                    </ul>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">2. Présentation et principe :</h2>
                    <p className="mb-4">
                        Est désigné ci-après : Utilisateur, tout internaute se connectant et utilisant le site susnommé : www.cloris-avocat.fr. Le site regroupe un ensemble de services, dans l'état, mis à la disposition des utilisateurs. Il est précisé que ces derniers doivent rester courtois et faire preuve de bonne foi.
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">3. Accessibilité :</h2>
                    <p className="mb-4">
                        Le site www.cloris-avocat.fr est par principe accessible aux utilisateurs 24/24h, 7/7j, sauf interruption, programmée ou non, pour les besoins de sa maintenance ou en cas de force majeure.
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">4. Propriété intellectuelle :</h2>
                    <p className="mb-4">
                        Maître Solal CLORIS est propriétaire exclusif de tous les droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, tant sur la structure que sur les textes, images, graphismes, logo, icônes, sons, logiciels…
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">5. Liens hypertextes et cookies :</h2>
                    <p className="mb-4">
                        Le site www.cloris-avocat.fr peut contenir un certain nombre de liens hypertextes vers d’autres sites, mis en place avec l’autorisation de Maître Solal CLORIS. Notre site n'utilise pas de cookies et ne collecte donc aucune donnée via des cookies.
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">6. Protection des biens et des personnes - gestion des données personnelles :</h2>
                    <p className="mb-4">
                        En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l'article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">7. Gestion des données personnelles :</h2>
                    <p className="mb-4">
                        Le site www.cloris-avocat.fr ne collecte des informations personnelles relatives à l'utilisateur que pour le besoin de certains services proposés par le site. L'utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie.
                    </p>
                </section>
            </div>
        );
    }
};

export default Mentions;
