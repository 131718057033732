/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import React, { useState, useEffect } from 'react';
import { PlayIcon } from '@heroicons/react/24/solid';
import he from 'he';
import { getTranslation } from './Helper';

export default function Videos({ lang }) {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const contenu = {
        heading: {
            'fr': 'La chaîne Youtube',
            'en': 'The YouTube Channel'
        },
        intro: {
            'fr': 'Retrouvez mes vidéos pour rester informé sur l\'actualité en matière de droit des étrangers et de la nationalité française !',
            'en': 'Check out my videos to stay updated on the latest in immigration law and French nationality!'
        },
        chargement: {
            'fr': '...Chargement des vidéos',
            'en': '...Loading Youtube Videos'
        },
        erreur: {
            'fr': 'Une erreur s\'est produite et les vidéos n\'ont pas pu être chargées.',
            'en': 'An error occured while fetching the Videos.'
        }
    };

    function safeParseJSON(jsonString, fallback = []) {
        if (!jsonString || typeof jsonString !== 'string' || (jsonString[0] !== '{' && jsonString[0] !== '[')) {
            return fallback;
        }

        try {
            return JSON.parse(jsonString);
        } catch (e) {
            return fallback;
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_MAIL_URL}/youtube-search`);
                if (!response.ok) {
                    throw new Error(`API call failed with status: ${response.status}`);
                }
                const data = await response.json();
                setVideos(data.items || []);
                setLoading(false);
                localStorage.setItem('videosData', JSON.stringify(data.items || []));
                localStorage.setItem('videosDataTimestamp', Date.now());
            } catch (error) {
                console.error('Error fetching or parsing data: ', error);
                setError(error);
                setLoading(false);
            }
        };

        const storedData = localStorage.getItem('videosData');
        const storedTimestamp = localStorage.getItem('videosDataTimestamp');
        const currentTime = Date.now();
        const freshnessLimit = 4 * 60 * 60 * 1000; // 4 hours

        if (storedData && storedTimestamp && currentTime - storedTimestamp < freshnessLimit) {
            const parsedData = safeParseJSON(storedData);
            setVideos(parsedData);
            setLoading(false);
        } else {
            fetchData();
        }
    }, []); // Removed dependencies since they are not directly used

    if (loading) {
        return (
            <div className='bg-gray-100 relative'>
                <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
                    <h3 className="text-2xl font-bold tracking-tight text-gray-900">{getTranslation(contenu.heading, lang)}</h3>
                    <p className="mt-1 w-full md:w-1/2 text-base text-gray-500">
                        {getTranslation(contenu.intro, lang)}
                    </p>
                </div>
                <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
                    <p className="text-sm text-gray-500">
                        {getTranslation(contenu.chargement, lang)}
                    </p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className='bg-gray-100 relative'>
                <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
                    <h3 className="text-2xl font-bold tracking-tight text-gray-900">{getTranslation(contenu.heading, lang)}</h3>
                    <p className="mt-1 w-full md:w-1/2 text-base text-gray-500">
                        {getTranslation(contenu.intro, lang)}
                    </p>
                </div>
                <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
                    <p className="text-sm text-gray-500">
                        {getTranslation(contenu.erreur, lang)}
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className='bg-gray-100 relative'>
            <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
                <h3 className="text-2xl font-bold tracking-tight text-gray-900">{getTranslation(contenu.heading, lang)}</h3>
                <p className="mt-1 w-full md:w-1/2 text-base text-gray-500">
                    {getTranslation(contenu.intro, lang)}
                </p>
            </div>
            <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
                <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                    {videos.slice(0, 8).map((video) => (
                        <li key={video.id.videoId} className="relative">
                            <a href={`https://www.youtube.com/watch?v=${video.id.videoId}`} target="_blank" rel="noopener noreferrer" className="group">
                                <div className="relative aspect-w-16 aspect-h-9 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500">
                                    <img src={video.snippet.thumbnails.high.url} alt={video.snippet.description} className="object-cover pointer-events-none group-hover:opacity-75" />
                                    <div className="absolute z-1 inset-0 bg-black bg-opacity-20 flex justify-center items-center">
                                        <PlayIcon className="w-12 h-12 text-red-600" />
                                    </div>
                                </div>
                                <p className="mt-2 block text-sm font-semibold text-gray-900 pointer-events-none">
                                    {he.decode(video.snippet.title)}
                                </p>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
