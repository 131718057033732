import { getTranslation } from "./Helper"

const navigation = [
    {
        name: {
            'fr': 'Mentions légales',
            'en': 'Legal Notices'
        },
        href: {
            'fr': '/mentions-legales',
            'en': '/en/legal-notices'
        }
    },
    {
        name: 'Linkedin',
        href: 'https://www.linkedin.com/in/solal-cloris-88000915',
        icon: (props) => (
            <svg className="h-7 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path
                    fillRule="evenodd"
                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                    clipRule="evenodd"
                />
            </svg>
        ),
    },
    {
        name: 'YouTube',
        href: 'https://www.youtube.com/@MaitreSolalCloris',
        icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                <path
                    fillRule="evenodd"
                    d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                    clipRule="evenodd"
                />
            </svg>
        ),
    },
]

const footertext = {
    'fr': 'avocat inscrit au Barreau du Val-de-Marne (ressort de la Cour d\'appel de Paris) ',
    'en': 'attorney registered with the Val-de-Marne Bar Association (jurisdiction of the Paris Court of Appeal)'
}

export default function Footer({ lang }) {
    return (
        <footer className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
                <div className="flex justify-center space-x-6 md:order-2">
                    {navigation.map((item) => (
                        item.icon ? (
                            <a key={item.name} href={item.href} target="_blank" className="text-gray-400 hover:text-gray-500">
                                <span className="sr-only">{item.name}</span>
                                <item.icon className="h-6 w-6" aria-hidden="true" />
                            </a>
                        ) : (
                            <a key={getTranslation(item.name, lang)} href={getTranslation(item.href, lang)} className="text-gray-400 hover:text-gray-500">
                                {getTranslation(item.name, lang)}
                            </a>
                        )
                    ))}
                </div>
                <div className="mt-8 md:order-1 md:mt-0">
                    <p className="text-center text-xs leading-5 text-gray-500">
                        &copy; 2024 M<sup>e</sup> Solal Cloris, {getTranslation(footertext, lang)}
                    </p>
                </div>
            </div>
        </footer>
    )
}
