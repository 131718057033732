import { getTranslation } from './Helper';
import { VideoCameraIcon, BuildingOfficeIcon, PhoneIcon, QuestionMarkCircleIcon, ClipboardDocumentIcon } from '@heroicons/react/24/outline'
import Tip from './Tip'

const tarifs = [
    {
        key: 'cabinet',
        label: {
            'fr': 'Rendez-vous cabinet',
            'en': 'In-Office Appointment'
        },
        desc: {
            'fr': 'Durée : 45 min',
            'en': 'Duration: 45 min'
        },
        content: {
            'fr': 'Maître Cloris vous reçoit à son cabinet pour un 1er rendez-vous. Ce premier rdv de 45 min, sans engagement, permet réciproquement de faire connaissance, de connaître vos droits et la manière de traiter la problématique à laquelle vous êtes confronté.',
            'en': 'Maître Cloris welcomes you for a first meeting at his office. This initial 45-minute consultation, with no obligation, allows both parties to get acquainted, understand your rights, and discuss the approach to your legal issues.'
        },
        prix: 165,
        cta: {
            'fr': 'Prendre RDV',
            'en': 'Schedule Appointment'
        },
        icon: BuildingOfficeIcon,
        cible: 'https://consultation.avocat.fr/consultation-cabinet/forms.php?source=profile&targetid=40529'
    },
    {
        key: 'video',
        label: {
            'fr': 'Consultation vidéo',
            'en': 'Video Consultation'
        },
        desc: {
            'fr': 'Durée : 45 min',
            'en': 'Duration: 45 min'
        },
        content: {
            'fr': 'Effectuez une consultation vidéo avec Maître Cloris en toute sécurité, n’importe où avec votre smartphone ou votre ordinateur. Cette consultation vidéo de 45 min vous permet d\'obtenir des éléments de réponse concrets et rapides à la question que vous vous posez.',
            'en': 'Have a secure video consultation with Maître Cloris from anywhere, using your smartphone or computer. This 45-minute video consultation provides concrete and swift answers to your legal inquiries.'
        },
        prix: 150,
        cta: {
            'fr': 'Prendre RDV',
            'en': 'Schedule Appointment'
        },
        icon: VideoCameraIcon,
        cible: 'https://consultation.avocat.fr/consultation-video/forms.php?source=profile&targetid=40529'
    },
    {
        key: 'telephonique',
        label: {
            'fr': 'Consultation téléphonique',
            'en': 'Phone Consultation'
        },
        desc: {
            'fr': 'Durée : 45 min',
            'en': 'Duration: 45 min'
        },
        content: {
            'fr': 'Maître Cloris vous rappelle pour répondre à vos questions juridiques. Cette consultation téléphonique de 45 min vous permet d\'obtenir des éléments de réponse concrets et rapides à la question que vous vous posez.',
            'en': 'Maître Cloris will call you back to answer your legal questions. This 45-minute phone consultation provides concrete and quick answers to your inquiries.'
        },
        prix: 150,
        cta: {
            'fr': 'Demander un rappel',
            'en': 'Request a Callback'
        },
        icon: PhoneIcon,
        cible: 'https://consultation.avocat.fr/consultation-telephonique/forms.php?source=profile&targetid=40529'
    },
    {
        key: 'question',
        label: {
            'fr': 'Question simple',
            'en': 'Simple Question'
        },
        desc: {
            'fr': 'Réponse concise à votre question (moins de 1.000 caractères)',
            'en': 'Concise answer to your question (less than 1,000 characters)'
        },
        content: {
            'fr': 'Maître Cloris répond à vos questions juridiques par écrit. Si vous souhaitez accompagner votre question d\'une pièce jointe, la consultation juridique est adaptée, sinon la question simple (moins de 1.000 caractères) répondra parfaitement à votre question de droit.',
            'en': 'Maître Cloris answers your legal questions in writing. If you wish to include an attachment with your question, a full legal consultation is suited; otherwise, a simple question (less than 1,000 characters) will perfectly address your legal query.'
        },
        prix: 45,
        cta: {
            'fr': 'Poser une question',
            'en': 'Ask a Question'
        },
        icon: QuestionMarkCircleIcon,
        cible: 'https://consultation.avocat.fr/question-simple/forms.php?source=profile&targetid=40529'
    },
    {
        key: 'consultation',
        label: {
            'fr': 'Consultation écrite',
            'en': 'Written Consultation'
        },
        desc: {
            'fr': 'Etude de votre dossier + possibilité d\'ajout d\'une pièce jointe',
            'en': 'Review of your case + option to add an attachment'
        },
        content: {
            'fr': 'Maître Cloris répond à vos questions juridiques par écrit. Si vous souhaitez accompagner votre question d\'une pièce jointe, la consultation juridique est adaptée, sinon la question simple (moins de 1.000 caractères) répondra parfaitement à votre question de droit.',
            'en': 'Maître Cloris responds to your legal questions in writing. Should you wish to supplement your question with an attachment, a detailed legal consultation is appropriate; for straightforward inquiries, a simple question (less than 1,000 characters) will suffice.'
        },
        prix: 240,
        cta: {
            'fr': 'Consulter par écrit',
            'en': 'Consult in Writing'
        },
        icon: ClipboardDocumentIcon,
        cible: 'https://consultation.avocat.fr/consultation-juridique/forms.php?source=profile&targetid=40529'
    },
]

function NicePrice(price, lang) {
    if (lang === 'en')
        return <>€{price}</>;
    return <>{price} €</>;
}



export default function WidgetRdv({ lang }) {

    return (

        <div className="bg-white py-4 md:py-4">

            <div className="overflow-hidden bg-white shadow sm:rounded-md">
                <ul className="divide-y divide-gray-200">
                    {tarifs.map((item) => (
                        <li key={item.key} className="px-4 py-4 sm:px-6">
                            <div className="md:flex md:items-center md:justify-between">
                                <item.icon className="hidden md:inline-flex  h-6 w-6 mb-1 mr-5 text-black" aria-hidden="true" />
                                <div className="min-w-0 flex-1 ml-3">
                                    <span className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{getTranslation(item.label, lang)}<Tip element={item} lang={lang} /></span>
                                    <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">{getTranslation(item.desc, lang)}</p>
                                </div>
                                <div className="md:hidden min-w-0 flex-1 ml-3">
                                    <span className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                                        {NicePrice(item.prix, lang)}
                                    </span>
                                </div>
                                <div className="relative mt-4 flex md:ml-4 md:mt-0">
                                    <a href={item.cible} target='_blank' rel="noreferrer" >
                                        <button
                                            type="button"
                                            className="ml-3 inline-flex items-center rounded-md bg-sky-50 px-2.5 py-1.5 text-sm font-semibold text-sky-600 shadow-sm hover:bg-sky-100"
                                        >
                                            <item.icon className="inline-flex md:hidden h-5 w-5 mr-3 text-sky-600" aria-hidden="true" />{getTranslation(item.cta, lang)}
                                        </button>
                                    </a>
                                    <div>
                                        <div className="absolute right-100 w-20">
                                            <span className="hidden md:inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                                                {NicePrice(item.prix, lang)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}