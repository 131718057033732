import { getTranslation } from "./Helper"
import { BuildingStorefrontIcon, FlagIcon, GlobeEuropeAfricaIcon } from '@heroicons/react/24/outline'
import PhotoReunion from '../img/photo-reunion.jpg'

const contenu = {
    heading: {
        'fr': 'À vos côtés pour vous conseiller et vous défendre',
        'en': 'By Your Side to Advise and Defend You'
    },
    intro: {
        'fr': 'Le Cabinet de M<sup>e</sup> Solal Cloris intervient principalement en droit des étrangers et de la nationalité française sur l\'ensemble du territoire. Soucieux de contribuer à un meilleur accès au droit, les avocats du cabinet s\'efforcent de rester disponibles et privilégient un lien direct et qualitatif avec chaque client.',
        'en': 'The Law Firm of Maître Solal Cloris primarily focuses on immigration and French nationality law throughout the territory. Dedicated to facilitating better access to legal services, our lawyers make a concerted effort to be available and maintain a direct, high-quality connection with each client.'
    },
    rdv: {
        'fr': 'Prendre rendez-vous',
        'en': 'Schedule an Appointment'
    }
}

const supportLinks = [
    {
        key: 'etrangers',
        name: {
            'fr': 'Droit des étrangers',
            'en': 'Immigration Law'
        },
        href: '#',
        description: {
            'fr': 'Le cabinet intervient en droit des étrangers dans la préparation des demandes de visa auprès des ambassades et des consulats français à l\'étranger et de titre de séjour (étudiant, salarié, vie privée et familiale, passeport talent - première demande ou renouvellement). En cas de décisions défavorables, le cabinet vous assiste et vous représente dans la contestation de celles-ci devant les juridictions administratives (tribunal administratif, cour administrative d\'appel).',
            'en': 'The firm specializes in immigration law, handling visa applications with French embassies and consulates abroad, and residence permit applications (for students, employees, private and family life, talent passport - initial applications or renewals). In the event of unfavorable decisions, the firm provides assistance and represents you in challenging these decisions before administrative courts (administrative tribunal, administrative court of appeal).'
        },
        icon: GlobeEuropeAfricaIcon,
    },
    {
        key: 'nationalite',
        name: {
            'fr': 'Droit de la nationalité',
            'en': 'Nationality Law'
        },
        href: '#',
        description: {
            'fr': 'Le cabinet assiste ses clients dans l\'établissement de la preuve de leur nationalité française (demande de Certificat de Nationalité Française, action déclaratoire devant le Tribunal Judiciaire) ainsi que dans l\'acquisition de la nationalité française (refus d\'enregistrement d\'une déclaration de nationalité, demande de naturalisation).',
            'en': 'The firm assists clients in establishing proof of their French nationality (application for French Nationality Certificate, declaratory action before the Judicial Court) as well as in acquiring French nationality (rejection of nationality declaration, naturalization application).'
        },
        icon: FlagIcon,
    },
    {
        key: 'commercial',
        name: {
            'fr': 'Droit commercial',
            'en': 'Commercial Law'
        },
        href: '#',
        description: {
            'fr': 'Le cabinet a développé une expertise solide en matière commercial, accompagnant de nombreux commerçants dans leurs opérations d’acquisition / vente de fonds de commerce et dans leurs relations avec le bailleur (baux commerciaux). Lorsqu\'un contentieux est inévitable, le cabinet assiste ses clients devant les juridictions civiles et commerciales.',
            'en': 'The firm has developed solid expertise in commercial matters, assisting numerous merchants with the acquisition/sale of business assets and in their dealings with landlords (commercial leases). When litigation is unavoidable, the firm represents its clients in civil and commercial courts.'
        },
        icon: BuildingStorefrontIcon,
    },
]

export default function Services({ setOpen, lang }) {
    return (
        <div className="bg-white">
            {/* Header */}
            <div className="relative bg-gray-800 pb-32">
                <div className="absolute inset-0">
                    <img
                        className="h-full w-full object-cover"
                        src={PhotoReunion}
                        alt=""
                    />
                    <div className="absolute inset-0 bg-gray-800 mix-blend-multiply" aria-hidden="true" />
                </div>
                <div className="relative mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
                    <h1 className="text-4xl font-bold tracking-tight text-white md:text-5xl lg:text-6xl">{getTranslation(contenu.heading, lang)}</h1>
                    <p className="mt-6 max-w-3xl text-xl text-gray-300">
                        {getTranslation(contenu.intro, lang)}
                    </p>

                </div>
            </div>

            {/* Overlapping cards */}
            <section className="relative z-3 mx-auto -mt-32 max-w-7xl px-6 pb-32 lg:px-8" aria-labelledby="contact-heading">
                <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-0">
                    {supportLinks.map((link) => (
                        <div key={link.key} className="flex flex-col rounded-2xl bg-white shadow-xl">
                            <div className="relative flex-1 px-6 pb-8 pt-16 md:px-8">
                                <div className="absolute top-0 inline-block -translate-y-1/2 transform rounded-xl bg-sky-600 p-5 shadow-lg">
                                    <link.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                </div>
                                <h3 className="text-xl font-medium text-gray-900">{getTranslation(link.name, lang)}</h3>
                                <p className="mt-4 text-base text-gray-500">{getTranslation(link.description, lang)}</p>
                            </div>
                            <div className="rounded-bl-2xl rounded-br-2xl bg-gray-50 p-6 md:px-8">
                                <a
                                    href={link.href}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setOpen();
                                    }}
                                    className="text-base font-medium text-sky-700 hover:text-sky-600"
                                >
                                    {getTranslation(contenu.rdv, lang)}<span aria-hidden="true"> &rarr;</span>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    )
}
