import Contact from '../components/Contact';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import Team from '../components/Team';
import Videos from '../components/Videos';
import Services from '../components/Services';
import Panel from '../components/Panel';
import Hero from '../components/Hero';
import { useState } from 'react';
import { useUpdateMetaDescription, useUpdateLangAttribute } from '../components/Helper';
import { getTranslation } from '../components/Helper';

function Home({ lang }) {
    const [stateOpen, setStateOpen] = useState(false);

    const description = {
        'fr': 'Maître Solal CLORIS, Avocat inscrit au Barreau du Val-de-Marne (ressort de la Cour d\'appel de Paris) est spécialisé dans le droit commercial et le droit des étrangers. Cabinet situé à Saint-Mandé, au 59, av.du Général de Gaulle. Prise de rendez-vous en ligne.',
        'en': 'Maître Solal CLORIS, an attorney registered with the Val-de-Marne Bar Association (jurisdiction of the Paris Court of Appeal), specializes in commercial law and immigration law. The firm is located in Saint-Mandé, at 59 Avenue du Général de Gaulle. Online appointment scheduling available.'
    }


    useUpdateMetaDescription(getTranslation(description, lang));
    useUpdateLangAttribute(lang);

    return (
        <>
            <header>
                <NavBar setOpen={() => setStateOpen(open => !open)} lang={lang} context='home' />
                <Panel open={stateOpen === true ? true : false} setOpen={() => setStateOpen()} lang={lang} />
            </header>
            <Hero lang={lang} />
            <section id="equipe" className="anchor-target">
                <Team lang={lang} />
            </section>
            <section id="services" className="anchor-target">
                <Services setOpen={() => setStateOpen(open => !open)} lang={lang} />
            </section>
            <section id="contact" className="anchor-target">
                <Contact lang={lang} />
            </section>
            <section id="infos" className="anchor-target">
                <Videos lang={lang} />
            </section>
            <footer>
                <Footer lang={lang} />
            </footer>
        </>
    );
}

export default Home;
