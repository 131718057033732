import { getTranslation } from "./Helper"
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import WidgetRdv from './WidgetRdv'


export default function Panel({ open, setOpen, lang }) {

    const contenu = {
        heading: {
            'fr': 'Prendre rendez-vous avec M<sup>e</sup> Cloris',
            'en': 'Schedule an appointment with Maître Cloris'
        },
        intro: {
            'fr': 'Maître Cloris vous reçoit à son cabinet pour un 1er rendez-vous, ou par téléphone lors d\'un consultation téléphonique de 45 minutes.',
            'en': 'Maître Cloris welcomes you to his office for an initial appointment or by phone for a 45-minute telephone consultation.'
        },
        close: {
            'fr': 'Fermer',
            'en': 'Close'
        },
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen md:max-w-4xl">
                                    <div className="flex h-full flex-col overflow-y-scroll overflow-x-hidden bg-white py-6 shadow-xl">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-2xl font-semibold leading-7 text-gray-900">
                                                    {getTranslation(contenu.heading, lang)}
                                                    <p className="flex w-5/6 mt-2 font-normal item-left text-sm text-gray-900">
                                                        {getTranslation(contenu.intro, lang)}
                                                    </p>

                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        <span className="absolute -inset-2.5" />
                                                        <span className="sr-only">{getTranslation(contenu.close, lang)}</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                            <WidgetRdv lang={lang} />
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
