import parse from 'html-react-parser';
import { useEffect } from 'react';

export function getTranslation(element, lang = 'fr') {
    if (element[lang]) {
        return parse(element[lang]);
    } else {
        console.error('Language version not found. Falling back to default.');
        return parse(element['fr']) || 'Not available';
    }
}

export function useUpdateMetaDescription(description) {
    useEffect(() => {
        document.querySelector('meta[name="description"]').setAttribute("content", description);
    }, [description]);
}


export function useUpdateLangAttribute(lang) {
    useEffect(() => {
        document.documentElement.lang = lang;
    }, [lang]);
}
