import { getTranslation } from "./Helper"
import Solal from '../img/solal.jpg'
import Manon from '../img/manon.jpg'
import Catherine from '../img/catherine.jpg'


const contenu = {
    heading: {
        'fr': 'Un cabinet à votre service',
        'en': 'Your Dedicated Legal Team'
    },
    intro: {
        'fr': 'Nous sommes une équipe d\'avocats dédiés à votre service.',
        'en': 'We are a team of dedicated lawyers at your service.'
    }
}


const lawyer = [
    {
        name: 'Solal Cloris',
        role: {
            'fr': 'Avocat fondateur',
            'en': 'Founding Lawyer'
        },
        imageUrl: Solal,
        bio: {
            'fr': 'M<sup>e</sup> Cloris est engagé dans la protection et la défense des droits des étrangers depuis près de 20 ans.  Avocat depuis 11 ans, il assiste les ressortissants étrangers et les français dans l\'établissement de la preuve de leur nationalité française sur l\'ensemble du territoire. Il assiste en outre les commerçants dans leur opérations les plus importantes : la cession du fonds de commerce / société et la négociation des baux commerciaux.',
            'en': 'Maître Cloris has been committed to protecting and defending the rights of foreigners for nearly 20 years. As a lawyer for 11 years, he supports both foreign nationals and French citizens in establishing proof of their French nationality across the territory. Additionally, he aids merchants in their most significant operations: the transfer of business assets/company and the negotiation of commercial leases.'
        },
        linkedinUrl: 'https://www.linkedin.com/in/solal-cloris-88000915',
    },
]

const people = [
    {
        name: 'Manon Leroy',
        role: {
            'fr': 'Avocate collaboratrice',
            'en': 'Associate Lawyer'
        },
        imageUrl: Manon,
        bio: {
            'fr': 'Diplômée de l\'Université Paris I Panthéon- Sorbonne, Me Leroy a rejoint le cabinet en janvier 2024 et intervient tout particulièrement dans le contentieux judiciaire de la nationalité française.',
            'en': 'A graduate of the University of Paris I Panthéon-Sorbonne, Maître Leroy joined the firm in January 2024, specializing in French nationality judicial litigation.'
        },
        linkedinUrl: 'https://www.linkedin.com/in/manon-leroy-8a1950158',
    },
{
        name: 'Catherine Walton',
        role: {
            'fr': 'Avocate collaboratrice',
            'en': 'Associate Lawyer'
        },
        imageUrl: Catherine,
        bio: {
            'fr': 'Franco-britannique, Catherine Walton est titulaire d\'un Master droits de l\'Homme obtenu à l’Université Paris Nanterre. Elle a intégré le cabinet en septembre 2024.',
            'en': 'Franco-British, Catherine Walton holds a Master\'s degree in Human Rights from the University of Paris Nanterre. She joined the firm in September 2024.'
        },
        linkedinUrl: 'https://www.linkedin.com/in/catherine-walton-a32ba021a',
    },
]


export default function Teams({ lang }) {
    return (
        <div className="bg-white py-12 md:py-16">
            <div className="mt-10">
                <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-10 gap-y-20 px-6 lg:px-8 xl:grid-cols-12">
                    <div className="max-w-2xl xl:col-span-6">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{getTranslation(contenu.heading, lang)}</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            {getTranslation(contenu.intro, lang)}
                        </p>
                        <ul className="space-y-12 divide-y divide-gray-200 xl:col-span-6 mt-10">
                            {lawyer.map((person) => (
                                <li key={person.name} className="flex flex-col gap-10 pt-12 sm:flex-row">
                                    <img className="aspect-[4/5] w-52 h-65 flex-none rounded-2xl object-cover" src={person.imageUrl} alt="" />
                                    <div className="max-w-xl flex-auto">
                                        <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                                        <p className="text-base leading-7 text-gray-600">{getTranslation(person.role, lang)}</p>
                                        <p className="mt-6 text-base leading-7 text-gray-600">{getTranslation(person.bio, lang)}</p>
                                        <ul className="mt-6 flex gap-x-6">
                                            <li>
                                                <a href={person.linkedinUrl} target="_blank" className="text-gray-400 hover:text-gray-500" rel="noreferrer">
                                                    <span className="sr-only">LinkedIn</span>
                                                    <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <ul className="-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-6 xl:ml-20">
                        {people.map((person) => (
                            <li key={person.name} className="flex flex-col gap-10 pt-12 sm:flex-row">
                                <img className="aspect-[4/5] w-32 h-40 flex-none rounded-2xl object-cover" src={person.imageUrl} alt="" />
                                <div className="max-w-xl flex-auto">
                                    <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                                    <p className="text-base leading-7 text-gray-600">{getTranslation(person.role, lang)}</p>
                                    <p className="mt-6 text-base leading-7 text-gray-600">{getTranslation(person.bio, lang)}</p>
                                    <ul className="mt-6 flex gap-x-6">
                                        <li>
                                            <a href={person.linkedinUrl} target="_blank" className="text-gray-400 hover:text-gray-500" rel="noreferrer">
                                                <span className="sr-only">LinkedIn</span>
                                                <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}
