import { getTranslation } from './Helper';
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Flowbite } from 'flowbite-react';
import { Tooltip } from 'flowbite-react';

const customTheme = {
    "target": "w-fit",
    "animation": "transition-opacity",
    "arrow": {
        "base": "absolute z-10 h-2 w-2 rotate-45",
        "style": {
            "dark": "bg-gray-200 dark:bg-gray-700",
            "light": "bg-white",
            "auto": "bg-white dark:bg-gray-700"
        },
        "placement": "-4px"
    },
    "base": "absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm",
    "hidden": "invisible opacity-0",
    "style": {
        "dark": "w-80 bg-gray-200 text-black dark:bg-gray-700",
        "light": "border border-gray-200 bg-white text-gray-900",
        "auto": "border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white"
    },
    "content": "relative z-20"
};

export default function Tip({ element, lang }) {

    return (
        <>
            <Flowbite theme={{ theme: customTheme }}>
                <div className='inline-block'>
                    <Tooltip theme={customTheme} content={getTranslation(element.content, lang)}>
                        <span className="relative inline-block">
                            <InformationCircleIcon className="absolute bottom-0 left-0.5 h-4 inline-flex w-4 mb-1 mr-5 text-sky-700 cursor-pointer" aria-hidden="true" />
                        </span>
                    </Tooltip>
                </div>
            </Flowbite>
        </>


    )
}
