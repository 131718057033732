import { useEffect } from 'react';
import { getTranslation } from './Helper'
import PhotoReunion from '../img/photo-reunion.jpg'

const contenu = {
    heading: {
        'fr': 'Une équipe d\'avocats professionnels à votre service.',
        'en': 'Your Dedicated Team of Legal Experts.'
    },
    decouvrir: {
        'fr': 'Découvrir l\'équipe',
        'en': 'Discover Our Team'
    },
    titre: {
        'fr': 'Solal Cloris, avocat à la cour',
        'en': 'Solal Cloris, Attorney at Law'
    },
    baseline: {
        'fr': 'À vos côtés pour vous conseiller et vous défendre',
        'en': 'Guiding and Defending You Every Step of the Way'
    },
    intro: {
        'fr': 'Le Cabinet de M<sup>e</sup> Solal Cloris intervient principalement en droit des étrangers et de la nationalité française sur l\'ensemble du territoire. Soucieux de contribuer à un meilleur accès au droit, les avocats du cabinet s\'efforcent de rester disponibles et privilégient un lien direct et qualitatif avec chaque client.',
        'en': 'The Law Offices of Solal Cloris specialize in immigration and French nationality law nationwide. With a commitment to improving legal access, our lawyers are dedicated to being accessible, fostering a direct and meaningful connection with each client.'
    },
    equipe: {
        'fr': 'L\'équipe',
        'en': 'Our Team'
    },
    services: {
        'fr': 'Les services',
        'en': 'Our Services'
    },
}


export default function Hero({ lang }) {

    useEffect(() => {
        const preloadImage = new Image();
        preloadImage.src = PhotoReunion;
    }, []);

    return (
        <div className="relative bg-white">
            <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
                <div className="px-6 pb-24 pt-6 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-20 xl:col-span-6">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <div className="hidden sm:mt-32 sm:flex lg:mt-16">
                            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                                {getTranslation(contenu.heading, lang)}{' '}
                                <a href="#equipe" className="whitespace-nowrap font-semibold text-sky-600">
                                    <span className="absolute inset-0" aria-hidden="true" />
                                    {getTranslation(contenu.decouvrir, lang)} <span aria-hidden="true">&rarr;</span>
                                </a>
                            </div>
                        </div>
                        <h1 className="mt-24 text-4xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-6xl">
                            {getTranslation(contenu.titre, lang)}
                        </h1>
                        <h3 className="mt-4 text-2xl font-bold tracking-tight text-gray-700 sm:mt-6 sm:text-4xl">
                            {getTranslation(contenu.baseline, lang)}
                        </h3>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            {getTranslation(contenu.intro, lang)}
                        </p>
                        <div className="mt-10 flex items-center gap-x-6">
                            <a
                                href="#equipe"
                                className="rounded-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                            >
                                {getTranslation(contenu.equipe, lang)}
                            </a>
                            <a href="#services" className="text-sm font-semibold leading-6 text-gray-900">
                                {getTranslation(contenu.services, lang)} <span aria-hidden="true">→</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
                    <img
                        className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
                        src={PhotoReunion}
                        alt="Le cabinet de Maître Solal Cloris"
                        loading="eager"
                    />
                </div>
            </div>
        </div>
    )
}
