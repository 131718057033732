import '../css/App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Page404 from './Page404';
import MentionsLegales from './MentionsLegales';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home lang='fr' />} />
        <Route path="/en" element={<Home lang='en' />} />
        <Route path="/mentions-legales" element={<MentionsLegales lang='fr' />} />
        <Route path="/en/legal-notices" element={<MentionsLegales lang='en' />} />
        <Route path="/*" element={<Page404 />} />
      </Routes>
    </Router>
  );
}

export default App;
