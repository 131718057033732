import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'; // Import ExclamationCircleIcon for error state
import { XMarkIcon } from '@heroicons/react/20/solid';
import { getTranslation } from './Helper';

export default function Notification({ status, lang }) { // Destructure state from props
    const [show, setShow] = useState(true);

    const contenu = {
        envoi: {
            'fr': 'Votre message a bien été envoyé',
            'en': 'Your message has been successfully sent'
        },
        nonenvoi: {
            'fr': 'Votre message n\'a pas été envoyé',
            'en': 'Your message was not sent'
        },
        texte: {
            'fr': 'Maître Solal Cloris a bien reçu votre message et vous répondra dans les meilleurs délais.',
            'en': 'Maître Solal Cloris has received your message and will respond as soon as possible.'
        },
        nontexte: {
            'fr': 'Une erreur s\'est produite et votre message n\'a pu être envoyé.',
            'en': 'An error occurred and your message could not be sent.'
        }
    }

    // Determine icon, background color, and text based on state
    const isSuccess = status === 'success';
    const icon = isSuccess ? <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" /> : <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />;
    const bgColor = isSuccess ? "bg-green-50" : "bg-red-50";
    const titleText = isSuccess ? getTranslation(contenu.envoi, lang) : getTranslation(contenu.nonenvoi, lang);
    const descriptionText = isSuccess ? getTranslation(contenu.texte, lang) : getTranslation(contenu.nontexte, lang);

    return (
        <>
            {/* Global notification live region */}
            <div
                aria-live="assertive"
                className="pointer-events-none fixed mt-14 inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    {/* Notification panel */}
                    <Transition
                        show={show}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg ${bgColor} shadow-lg ring-1 ring-black ring-opacity-5`}>
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        {icon}
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-gray-900">{titleText}</p>
                                        <p className="mt-1 text-sm text-gray-500">{descriptionText}</p>
                                    </div>
                                    <div className="ml-4 flex flex-shrink-0">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => setShow(false)}
                                        >
                                            <span className="sr-only">Fermer</span>
                                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    );
}
